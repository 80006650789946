import * as React from "react";
import { graphql } from "gatsby";
import _ from "lodash";
import BasicLayout from "../layouts/BasicLayout";
import ContactForm from "../components/contactForm";
import PageHeader from "../components/ui/pageHeader";
import PageBody from "../components/ui/pageBody";
import Container from "../components/ui/container";
import Section from "../components/ui/section";
import Heading from "../components/ui/heading";
import { MDXRenderer } from "gatsby-plugin-mdx";

class Contact extends React.Component {
  render() {
    const page = this.props.data.page;
    const partners = this.props.data.partners.edges.sort((a, b) => a.node.frontmatter.title.localeCompare(b.node.frontmatter.title));
    var countries = _.mapValues(
      _.groupBy(partners, "node.frontmatter.country"),
      (plist) =>
        plist.map((partner) => _.omit(partner, "node.frontmatter.country"))
    );
    const countryValues = Object.keys(countries).sort((a, b) => a.localeCompare(b));;
    return (
      <div>
        <BasicLayout pageTitle={page.frontmatter.title}>
          <PageHeader
            title={page.frontmatter.title}
            image={page.frontmatter.image}
            breadcrumb={[
              { page: page.frontmatter.title, path: this.props.uri },
            ]}
          />
          <Section background="bg-white">
            <Container padding>
              <Heading text="Contact us form" />
              <ContactForm location={this.props.uri} />
            </Container>
          </Section>
          <Section background="bg-gray-200">
            <Container padding>
              <PageBody>
                <MDXRenderer>{page.body}</MDXRenderer>
              </PageBody>
            </Container>
          </Section>
          <Section background="bg-gray-800">
            <Container padding>
              <PageBody>
                <Heading text="Our Partners" negative />
                <div className="flex flex-wrap">
                  {countryValues.map((country, i) => (
                    <div className="w-full lg:w-1/3 pr-5 mb-10 lg:mb-0" key={i}>
                      <div className="text-xl uppercase font-bold text-pink-400 mb-2">
                        {country}
                      </div>
                      <div>
                        {countries[country].map((partner, j) => (
                          <div key={j} className="text-white mb-5">
                            <div className="text-white text-lg font-bold">
                              {partner.node.frontmatter.title}
                            </div>
                            <div className="text-sm">
                              {partner.node.frontmatter.address && (
                                <div>{partner.node.frontmatter.address}</div>
                              )}
                              {partner.node.frontmatter.email && (
                                <div>{partner.node.frontmatter.email}</div>
                              )}
                              {partner.node.frontmatter.mobile && (
                                <div>{partner.node.frontmatter.mobile}</div>
                              )}
                              {partner.node.frontmatter.telephone && (
                                <div>{partner.node.frontmatter.telephone}</div>
                              )}
                              {partner.node.frontmatter.website && (
                                <a
                                  className="text-blue-200 hover:underline"
                                  href={partner.node.frontmatter.website}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {partner.node.frontmatter.website}
                                </a>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </PageBody>
            </Container>
          </Section>
        </BasicLayout>
      </div>
    );
  }
}

export default Contact;

export const ContactPageQuery = graphql`
  query ContactQuery {
    page: mdx(frontmatter: { id: { eq: "contact" } }) {
      frontmatter {
        title
        image
      }
      body
    }
    partners: allMdx(filter: { frontmatter: { layout: { eq: "partner" } } }) {
      edges {
        node {
          frontmatter {
            title
            mobile
            address
            country
            email
            type
            website
            telephone
          }
        }
      }
    }
  }
`;
