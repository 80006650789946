import React, { Component } from "react";
import validator from "validator";
import axios from "axios";
import * as qs from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { Link, graphql } from "gatsby";

interface Props {
  location: string;
}
interface State {
  name: string;
  email: string;
  mobile: string;
  organization: string;
  message: string;
  error: String[];
  success: boolean;
}

export default class contactForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.domRef = React.createRef();
    this.state = {
      name: "",
      email: "",
      mobile: "",
      organization: "",
      message: "",
      error: [],
      success: false,
    };
    this.handleNameInput = this.handleNameInput.bind(this);
    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleMobileInput = this.handleMobileInput.bind(this);
    this.handleOrgInput = this.handleOrgInput.bind(this);
    this.handleMsgInput = this.handleMsgInput.bind(this);
    this.validate = this.validate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleNameInput(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event.currentTarget.value;
    this.setState({ name });
  }
  handleEmailInput(event: React.ChangeEvent<HTMLInputElement>) {
    const email = event.currentTarget.value;
    this.setState({ email });
  }
  handleMobileInput(event: React.ChangeEvent<HTMLInputElement>) {
    const mobile = event.currentTarget.value;
    this.setState({ mobile });
  }
  handleOrgInput(event: React.ChangeEvent<HTMLInputElement>) {
    const organization = event.currentTarget.value;
    this.setState({ organization });
  }
  handleMsgInput(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const message = event.currentTarget.value;
    this.setState({ message });
  }
  validate() {
    this.setState({ error: [] });
    let error = [];
    validator.isEmpty(this.state.name) ? error.push("Name is required") : null;
    validator.isEmpty(this.state.email)
      ? error.push("Email is required")
      : null;
    validator.isEmail(this.state.email) ? null : error.push("Invalid email");
    validator.isMobilePhone(this.state.mobile)
      ? null
      : error.push("Invalid mobile phone");
    validator.isEmpty(this.state.message)
      ? error.push("Message is required")
      : null;
    this.setState({ error });
  }

  async handleSubmit(event: React.FormEvent<EventTarget>) {
    event.preventDefault();
    await this.validate();
    if (this.state.error.length === 0) {
      const formData = {};
      Object.keys(this.refs).map(
        (key) => (formData[key] = this.refs[key].value)
      );
      const axiosOptions = {
        url: this.props.location,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formData),
      };
      axios(axiosOptions)
        .then((response) => {
          console.log(response);
          this.setState({
            success: true,
          });
          // this.domRef.current.reset();
        })
        .catch((err) =>
          this.setState({
            error: [`Form could not be submitted.`],
          })
        );
    }
  }
  render() {
    const label_class =
      "block uppercase tracking-wide text-gray-700 font-bold text-md lg:text-lg mb-2";
    const input_class =
      "block appearance-none w-full bg-gray-100 text-gray-800 border rounded py-3 px-4 focus:bg-white focus:outline-none text-md lg:text-lg";
    return (
      <div>
        {this.state.error.length !== 0 && (
          <div
            className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4"
            role="alert"
          >
            <p className="font-bold mb-1">Submission Error:</p>
            <ul>
              {this.state.error.map((err, i) => (
                <li key={i} className="ml-2">
                  <p>{err}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
        {this.state.success ? (
          <div
            className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-5 lg:px-10 py-16 lg:py-32 text-center"
            role="alert"
          >
            <div className="py-1 text-6xl">
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ maxWidth: "64px" }}
              />
            </div>
            <div>
              <p className="font-bold">
                Thank you {this.state.name} for getting in touch!
              </p>
              <p className="text-sm">
                We have received your message and would like to thank you for
                writing to us. If your inquiry is urgent, please use the
                telephone number listed below to talk to one of our staff
                members. Otherwise, we will get back to you as soon as possible.
              </p>
              <Link className="text-sm text-teal-500 hover:underline" to="/">
                Go to home Page
              </Link>
            </div>
          </div>
        ) : (
          <form
            ref={this.domRef}
            name="Contact Form"
            method="POST"
            data-netlify="true"
            onSubmit={(event) => this.handleSubmit(event)}
            className="w-full"
          >
            <input
              ref="form-name"
              type="hidden"
              name="form-name"
              value="Contact Form"
            />
            <div className="w-full mb-6">
              <label htmlFor="name" className={label_class}>
                Full Name:
              </label>
              <input
                type="text"
                ref="name"
                name="name"
                value={this.state.name}
                onChange={this.handleNameInput}
                className={input_class}
              />
            </div>
            <div className="w-full mb-6">
              <label htmlFor="mobile" className={label_class}>
                Mobile Number:
              </label>
              <input
                type="text"
                ref="mobile"
                name="mobile"
                value={this.state.mobile}
                onChange={this.handleMobileInput}
                className={input_class}
              />
            </div>
            <div className="w-full mb-6">
              <label htmlFor="email" className={label_class}>
                Email:
              </label>
              <input
                type="text"
                ref="email"
                name="email"
                value={this.state.email}
                onChange={this.handleEmailInput}
                className={input_class}
              />
            </div>
            <div className="w-full mb-6">
              <label htmlFor="organization" className={label_class}>
                Organization:
              </label>
              <input
                type="text"
                ref="organization"
                name="organization"
                value={this.state.organization}
                onChange={this.handleOrgInput}
                className={input_class}
              />
            </div>
            <div className="w-full mb-6">
              <label htmlFor="message" className={label_class}>
                Message:
              </label>
              <textarea
                name="message"
                ref="message"
                value={this.state.message}
                onChange={this.handleMsgInput}
                className={input_class}
                rows={10}
              />
            </div>
            <div className="flex space-x-4 ">
              <input
                type="submit"
                ref="submit"
                value="Submit"
                className="flex-1 cursor-pointer lg:flex-none bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded "
              />
            </div>
          </form>
        )}
      </div>
    );
  }
}
