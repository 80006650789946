import * as React from "react";

interface Props {
  negative?: boolean;
  text: string;
}

interface State {}

class Heading extends React.Component<Props, State> {
  public render() {
    return (
      <div
        className={
          "font-bold text-2xl md:text-3xl lg:text-4xl mb-6 uppercase" +
          (this.props.negative ? " text-white" : " text-blue-900")
        }
      >
        {this.props.text}
      </div>
    );
  }
}

export default Heading;
